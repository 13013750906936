import React from 'react';

import { useCompanyFeatureFlag, usePermission } from '@attentive/acore-utils';
import { Permission } from '@attentive/data';

import { AdminRoutes } from '../../../../routes';
import { SidebarNavContainer, SidebarNavItem } from '../../client-layout/sidebar/SidebarNav';

export const AdminSidebarNav: React.FC = () => {
  const enableMessageExplorerTool = useCompanyFeatureFlag('ENABLE_MESSAGE_EXPLORER_TOOL_UI');
  const enableSubscriberAudit = useCompanyFeatureFlag('ENABLE_SUBSCRIBER_AUDIT');
  const enableLitigatorOptOut = useCompanyFeatureFlag('ENABLE_LITIGATOR_OPT_OUT');
  const enableNotificationContentManagement = useCompanyFeatureFlag(
    'ENABLE_NOTIFICATION_CONTENT_MANAGEMENT'
  );
  const enableOnboardingFlow = useCompanyFeatureFlag('ENABLE_ONBOARDING_FLOW_UI');
  const enableAdminAgentDashboard = useCompanyFeatureFlag('ENABLE_ADMIN_CONCIERGE_AGENTS');
  const enableEmailActivityTool = useCompanyFeatureFlag('ENABLE_RECORDING_SENDGRID_EMAIL_ACTIVITY');
  const enableAIJourneys = useCompanyFeatureFlag('ENABLE_AI_JOURNEYS_UI');
  const enableAIProTools = useCompanyFeatureFlag('ENABLE_AI_PRO_TOOLS');

  const isSuperUser = usePermission(Permission.SuperUserAccess);

  return (
    <SidebarNavContainer>
      <SidebarNavItem path={AdminRoutes.CampaignsControlCenter} label="Campaigns" />
      {isSuperUser && (
        <SidebarNavItem
          path={AdminRoutes.MigrationControlCenter}
          label="Migration Control Center"
        />
      )}
      {enableMessageExplorerTool && (
        <SidebarNavItem path={AdminRoutes.MessageExplorer} label="Message Explorer" />
      )}
      <SidebarNavItem path={AdminRoutes.Solutions} label="Solutions" />
      <SidebarNavItem path={AdminRoutes.Sftp} label="SFTP" />
      <SidebarNavItem path={AdminRoutes.CSTools} label="CS Tools" />
      <SidebarNavItem path={AdminRoutes.AIJourneysTools} label="AI Journeys Tools" />
      {enableAIProTools && <SidebarNavItem path={AdminRoutes.AIProTools} label="AI Pro Tools" />}
      <SidebarNavItem path={AdminRoutes.FeatureFlags} label="Feature Flags" />
      <SidebarNavItem path={AdminRoutes.CompanyCreation} label="Create Company" />
      <SidebarNavItem path={AdminRoutes.CompanySearch} label="Find Company" />
      {enableOnboardingFlow && (
        <SidebarNavItem path={AdminRoutes.OnboardingFlow} label="Onboarding Flow" />
      )}
      {enableSubscriberAudit && (
        <SidebarNavItem path={AdminRoutes.SubscriberAudit} label="Subscriber Audit" />
      )}
      {enableLitigatorOptOut && (
        <SidebarNavItem path={AdminRoutes.LitigatorOptOut} label="Litigator Opt-out" />
      )}
      {enableNotificationContentManagement && (
        <SidebarNavItem path={AdminRoutes.NotificationContent} label="Notification Content" />
      )}
      {isSuperUser && <SidebarNavItem path={AdminRoutes.IPManagement} label="IP Management" />}
      {isSuperUser && (
        <SidebarNavItem path={AdminRoutes.DomainSuppressions} label="Domain Suppressions" />
      )}
      {isSuperUser && <SidebarNavItem path={AdminRoutes.Marketplace} label="Marketplace" />}
      {isSuperUser && enableAdminAgentDashboard && (
        <SidebarNavItem path={AdminRoutes.ConciergeAgents} label="Concierge Agents" />
      )}
      {enableEmailActivityTool && (
        <SidebarNavItem path={AdminRoutes.EmailActivity} label="Email Activity" />
      )}
      {isSuperUser && <SidebarNavItem path={AdminRoutes.CreativeAudit} label="Creative Audit" />}
      <SidebarNavItem path={AdminRoutes.GenerativeAI} label="Generative AI" />
      {enableAIJourneys && <SidebarNavItem path={AdminRoutes.AIJourneys} label="AI Journeys" />}
      <SidebarNavItem path={AdminRoutes.AutomatedPrograms} label="Automated Programs" />
      <SidebarNavItem path={AdminRoutes.Datastreams} label="Datastreams" />
      <SidebarNavItem path={AdminRoutes.Workloads} label="Workloads" />
    </SidebarNavContainer>
  );
};
